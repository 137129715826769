<template>
  <div class="transactions-cash">
    <KTCodePreview v-bind:title="''">
      <template v-slot:preview>
        <b-card no-body class="full-width">
          <div
            class="wizard wizard-4"
            id="kt_wizard_v4"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <div class="wizard-nav" style="background-color: #eef0f8">
              <div class="wizard-steps">
                <div
                  class="wizard-step mb-0"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper p-2">
                    <div class="wizard-label">
                      <div class="wizard-title">{{ subTitle }}</div>
                    </div>
                  </div>
                </div>

                <div class="wizard-step ml-1 mb-0" data-wizard-type="step">
                  <div
                    class="wizard-wrapper p-2"
                    v-show="transactionId ? true : false"
                  >
                    <div class="wizard-label">
                      <div class="wizard-title">Đính kèm tệp</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-col
              cols="12"
              class="py-8 custom-card-body"
              data-wizard-type="step-content"
            >
              <b-form ref="form" lazy-validation class="row">
                <!-- MAIN CONTENT SECTION -->
                <b-container class="bv-example-row">
                  <b-row>
                    <!-- LEFT CONTENT -->
                    <b-col md="4" lg="4" sm="12">
                      <b-row>
                        <b-col md="12">
                          <!-- CREATED DATE -->
                          <b-form-group
                            label-cols-md="3"
                            label="Ngày tạo "
                            label-align-md="right"
                          >
                            <div class="input-group">
                              <date-picker
                                id="datecome-input"
                                placeholder="Ngày tạo"
                                class="form-control-sm input-style"
                                :config="dpConfigs.createDate"
                                v-model="$v.mainModel.createdAt.$model"
                                :wrap="true"
                                :disabled="
                                  !isShow(['TRANSACTION_PAY_PREV_DAY'])
                                "
                              ></date-picker>
                              <div
                                class="input-group-append"
                                v-if="isShow(['TRANSACTION_PAY_PREV_DAY'])"
                              >
                                <button
                                  class="
                                    btn btn-secondary btn-sm
                                    datepickerbutton
                                  "
                                  type="button"
                                  title="Toggle"
                                >
                                  <i class="far fa-calendar"></i>
                                </button>
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <!-- ACCOUNTANT -->
                        <b-col md="12">
                          <b-form-group
                            label-cols-md="3"
                            :label="
                              mode === 'transfer' ? 'Từ quỹ' : 'Tài khoản'
                            "
                            label-for="select-accountant"
                            label-align-md="right"
                            class="required-control"
                          >
                            <Autosuggest
                              :model="$v.mainModel.accountantName.$model"
                              :suggestions="filterModel.accountants"
                              :placeholder="placeHolderAccountant"
                              :limit="10"
                              @select="onSelect($event, 'Accountants')"
                              @change="onInputChangeAccountants"
                              :state="validateItem('accountantName')"
                              errorMessages="Yêu cầu chọn tài khoản."
                              suggestionName="text"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row v-if="mode === 'transfer'">
                        <!-- ACCOUNTANT -->
                        <b-col md="12">
                          <b-form-group
                            label-cols-md="3"
                            label="Đến quỹ"
                            label-for="select-accountant"
                            label-align-md="right"
                            class="required-control"
                          >
                            <b-form-select
                              id="select-accountant"
                              required
                              v-model="mainModel.accountantRefId"
                              aria-describedby="input-store-live-feedback"
                              :options="filterModel.accountants"
                              size="sm"
                              class="select-style"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="0">
                                  - {{ placeHolderAccountant }} -
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row v-if="mode !== 'transfer'">
                        <!-- TYPE -->
                        <b-col md="12">
                          <b-form-group
                            label-cols-md="3"
                            label="Loại phiếu"
                            label-for="select-transaction-type"
                            label-align-md="right"
                            class="required-control"
                          >
                            <b-form-select
                              id="select-transaction-type"
                              required
                              v-model="$v.mainModel.type.$model"
                              aria-describedby="input-store-live-feedback"
                              :options="transactionTypes"
                              size="sm"
                              @change="changeTypeChilds"
                              class="select-style"
                              :state="validateItem('type')"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null">
                                  - Loại phiếu -
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <b-form-invalid-feedback>
                              Yêu cầu chọn loại phiếu.
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row v-if="mode === 'cash'">
                        <!-- TYPE -->
                        <b-col md="12">
                          <b-form-group
                            label-cols-md="3"
                            label="Chi tiết"
                            label-for="select-transaction-type"
                            label-align-md="right"
                            class="required-control"
                          >
                            <b-form-select
                              id="child-type"
                              required
                              v-model="$v.mainModel.typeChild.$model"
                              aria-describedby="input-store-live-feedback"
                              :options="typeChilds"
                              size="sm"
                              value-field="id"
                              html-field="html"
                              @change="changeTypeParents"
                              class="select-style"
                              :state="validateItem('typeChild')"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null">
                                  - Chi tiết phiếu -
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <b-form-invalid-feedback>
                              Yêu cầu chọn chi tiết phiếu.
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <!-- AMOUNT -->
                        <b-col md="12">
                          <b-form-group
                            id="input-group-amount"
                            label-cols-md="3"
                            label="Số tiền"
                            label-for="input-amount"
                            label-align-md="right"
                            required
                            class="required-control"
                          >
                            <b-form-input
                              size="sm"
                              id="input-amount"
                              class="text-right"
                              v-model="$v.mainModel.amount.$model"
                              placeholder="Nhập số tiền"
                              v-mask="formater.currency"
                              :state="validateItem('amount')"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-amount-feedback">
                              Yêu cầu nhập số tiền
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <!-- NOTE -->
                        <b-col md="12">
                          <b-form-group
                            id="input-group-note"
                            label-cols-md="3"
                            label="Diễn giải"
                            label-for="input-note"
                            label-align-md="right"
                          >
                            <b-form-textarea
                              size="sm"
                              id="input-note"
                              class="input-style"
                              v-model="mainModel.note"
                              :placeholder="'Thêm ghi chú'"
                              :rows="3"
                              :max-rows="6"
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <!-- MID CONTENT -->
                    <b-col md="1" lg="1" sm="12"></b-col>
                    <!-- RIGHT CONTENT -->
                    <b-col md="5" lg="5" sm="12" v-if="mode != 'transfer'">
                      <b-row>
                        <!-- TYPE -->
                        <b-col md="12">
                          <b-form-group
                            label-cols-md="4"
                            label="Loại đối tượng"
                            label-for="select-contact-type"
                            label-align-md="right"
                            class="required-control"
                          >
                            <b-form-select
                              id="select-contact-type"
                              required
                              v-model="$v.mainModel.contactType.$model"
                              aria-describedby="input-store-live-feedback"
                              :options="filterModel.contactTypes"
                              size="sm"
                              @change.native="onChangeContactType"
                              class="select-style"
                              :disabled="
                                !mainModel.type || mainModel.type.length == 0
                              "
                              :state="validateItem('contactType')"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null">
                                  - Loại đối tượng -
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <b-form-invalid-feedback>
                              Vui lòng chọn đối tượng.
                            </b-form-invalid-feedback>
                            <label class="description" style="font-size: 10px">
                              Thu tiền từ đối tượng nào, chi tiền cho đối tượng
                              nào.</label
                            >
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row
                        v-show="
                          [
                            CONTACT_TYPE.Credit,
                            CONTACT_TYPE.Other,
                            CONTACT_TYPE.ShiftWork,
                            CONTACT_TYPE.ShipFee,
                          ].indexOf(mainModel.contactType) === -1
                        "
                      >
                        <b-col md="12">
                          <b-form-group
                            id="input-contact"
                            label-cols-md="4"
                            label="Đối tượng"
                            label-for="autosuggest__input"
                            label-align-md="right"
                            class="required-control"
                          >
                            <Autosuggest
                              :model="mainModel.contactName"
                              :suggestions="filterModel.contacts"
                              placeholder="để tìm kiếm đối tượng"
                              :limit="10"
                              @select="onSelect($event, 'Contact')"
                              @change="searchContacts"
                              suggestionName="text"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="
                          [CONTACT_TYPE.ShiftWork].indexOf(
                            mainModel.contactType,
                          ) === -1
                        "
                      >
                        <!-- TYPE -->
                        <b-col md="12">
                          <b-form-group
                            label-cols-md="4"
                            label="Loại chứng từ"
                            label-for="select-contact-type"
                            label-align-md="right"
                          >
                            <b-form-select
                              id="select-doc-type"
                              v-model="mainModel.reconciliationDocType"
                              aria-describedby="input-store-live-feedback"
                              :options="filterModel.docTypes"
                              size="sm"
                              class="select-style"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null">
                                  - Loại chứng từ -
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="
                          [CONTACT_TYPE.ShiftWork].indexOf(
                            mainModel.contactType,
                          ) === -1
                        "
                      >
                        <!-- AMOUNT -->
                        <b-col md="12">
                          <b-form-group
                            id="input-group-docid"
                            label-cols-md="4"
                            label="ID chứng từ"
                            label-for="input-docid"
                            label-align-md="right"
                          >
                            <b-form-input
                              size="sm"
                              id="input-docid"
                              class="text-left"
                              v-model="mainModel.reconciliationDocId"
                              placeholder="Nhập ID chứng từ"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="
                          ['4', '6', '20'].indexOf(mainModel.contactType) > -1
                        "
                      >
                        <!-- ACCOUNTANT -->
                        <b-col md="12">
                          <b-form-group
                            label-cols-md="4"
                            label="Tài khoản đối ứng "
                            label-for="autosuggest__input"
                            label-align-md="right"
                          >
                            <Autosuggest
                              :model="mainModel.accountantRefName"
                              :suggestions="filterModel.accountantRefs"
                              :placeholder="placeHolderAccountant"
                              :limit="10"
                              @select="onSelect($event, 'AccountantRefs')"
                              @change="onInputChangeAccountantRefs"
                              suggestionName="text"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-cols-md="4"
                            label="Đính kèm tập tin "
                            label-for="select-accountantref"
                            label-align-md="right"
                          >
                            <label
                              class="description mt-3"
                              style="font-size: 10px"
                              >Tệp đính kèm chỉ thêm được khi đã lưu
                              phiếu</label
                            >
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="1" lg="1" sm="12"></b-col>
                  </b-row>
                </b-container>
              </b-form>
            </b-col>
            <b-col
              cols="12"
              class="py-8 px-0 py-lg-15 px-lg-0 pb-5 full-width"
              data-wizard-type="step-content"
            >
              <UploadFile :id="transactionId" :entity="'transactions'" />
            </b-col>
          </div>
        </b-card>
      </template>
      <template v-slot:foot>
        <!-- <b-col lg="4" class="pb-2"> -->
        <b-container class="bv-example-row">
          <b-row>
            <b-col md="6" xs="12" sm="12">
              <b-button
                v-if="isShowSubmit"
                style="fontweight: 600; width: 70px; margin-right: 10px"
                variant="primary"
                size="sm"
                type="submit"
                @click="onSubmit"
                >Lưu
              </b-button>
              <b-button
                style="font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                @click="$router.go(-1)"
                >Hủy
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodeTab.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import datePicker from 'vue-bootstrap-datetimepicker';
import {
  TRANSACTION_TYPE,
  CONTACT_TYPE as CONTACT_TYPE_LIST,
  DOC_TYPE,
} from '@/utils/constants';
import { CONTACT_TYPE } from '@/utils/enum';
import _ from 'lodash';
import { cmdUrl } from '@/utils/apiUrl';
import ApiService from '@/core/services/api.service';
import { currencyMask, unMaskPrice, xoa_dau } from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { required, minValue } from 'vuelidate/lib/validators';
import { getToastInstance } from '@/utils/toastHelper';
import moment from 'moment';
import { checkPermissions } from '@/utils/saveDataToLocal';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import UploadFile from '@/view/modules/upload/Upload';

const checkCurrency = (value) => {
  const money = unMaskPrice(value);
  const moneyDec = _.toNumber(money);
  return moneyDec >= 0;
};

export default {
  name: 'transaction',
  mixins: [validationMixin],
  components: {
    KTCodePreview,
    datePicker,
    Autosuggest,
    UploadFile,
  },
  validations() {
    const commonValid = {
      type: {
        required,
      },
      accountantId: {
        required,
      },
      accountantName: {
        required,
      },
      contactType: {
        required,
      },
      amount: {
        required,
        checkCurrency,
      },
      createdAt: {
        required,
        minValue: minValue(0),
      },
    };

    if (this.mode === 'cash') {
      return {
        mainModel: {
          ...commonValid,
          typeChild: {
            required,
          },
        },
      };
    }

    if (this.mode === 'transfer') {
      return {
        mainModel: {
          accountantId: {
            required,
          },
          accountantName: {
            required,
          },
          amount: {
            required,
            checkCurrency,
          },
          createdAt: {
            required,
            minValue: minValue(0),
          },
        },
      };
    }
    return {
      mainModel: {
        ...commonValid,
      },
    };
  },
  mounted() {
    this.loadBreadcum();

    if (this.transactionId === 'new') {
      this.transactionId = null;
    }
    const mode = this.$route.params.mode;

    if (['cash', 'bank'].includes(mode)) {
      this.fetchTransactionCategory();
    }

    const query = this.$route.query || null;
    const tabName = query ? query.tabName : '';
    if (tabName) {
      const settings = _.keyBy(this.settings, 'tableName');
      const settingItem = settings[tabName] || null;
      this.currentWizard = settingItem ? settingItem.currentWizard : 1;
    }

    this.wizard = new KTWizard('kt_wizard_v4', {
      startStep: this.currentWizard,
      clickableSteps: true,
    });

    this.$nextTick(function() {
      this.wizard.on('change', this.onChangeTab);
    });

    this.fetchAllAccountants();
    this.fetchTransactionInfo();
    if (this.mainModelProp) {
      this.mainModel = Object.assign(this.mainModel, this.mainModelProp);
    }
    this.fetchStoreByUser();
  },
  props: {
    mainModelProp: Object,
  },
  data() {
    return {
      editFromList: false,
      CONTACT_TYPE,
      timeout: null,
      debounceMilliseconds: 250,
      mode: 'cash',
      title: '',
      subTitle: '',
      firstCheck: {
        amount: false,
      },
      mainModel: {
        id: 0,
        type: null,
        typeChild: null,
        amount: '',
        accountantId: null,
        accountantName: '',
        accountantRefId: null,
        accountantRefName: '',
        contactType: null,
        contactId: 0,
        contactName: '',
        reconciliationDocType: null,
        reconciliationDocId: null,
        note: '',
        attachments: '',
        isAuto: false,
        createdAt: new Date(),
        deleted: false,
        ...this.mainModelProp,
      },
      dpConfigs: {
        createDate: {
          format: 'DD/MM/YYYY',
          useCurrent: true,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
          allowInputToggle: true,
        },
      },
      filterModel: {
        accountants: [],
        contactTypes: CONTACT_TYPE_LIST,
        docTypes: DOC_TYPE,
        accountantRefs: [],
        contacts: [],
      },
      formater: {
        currency: currencyMask,
      },
      optionAccountants: [],
      optionAccountantsRef: [],
      selectedStoreId: null,
      typeChilds: [],
      typeChildsOld: [],
      checkReponse: true,
      settings: [
        {
          tableName: 'infor',
          currentWizard: 1,
        },
        {
          tableName: 'attachment',
          currentWizard: 2,
        },
      ],
      transactionId: null,
      isShowSubmit: true,
    };
  },
  created() {
    this.transactionId = this.$route.params.id || null;
  },
  computed: {
    placeHolderAccountant: function() {
      const mode = this.$route.params.mode;
      return mode === 'cash' ? 'tài khoản tiền mặt' : 'tài khoản ngân hàng';
    },
    transactionTypes: function() {
      const mode = this.$route.params.mode;
      const types = _.filter(TRANSACTION_TYPE, ['group', mode]);

      return types;
    },
  },
  methods: {
    ...getToastInstance(),
    onChangeTab() {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    },
    loadTitle: function(isFirst) {
      const mode = this.$route.params.mode;
      const action = this.$route.params.action;
      if (isFirst) this.mode = mode;
      switch (mode) {
        case 'cash':
          this.title = `${
            action === 'add' ? 'Thêm' : 'Chỉnh sửa'
          } phiếu thu chi`;
          this.subTitle = 'Thông tin phiếu thu chi';
          break;
        case 'bank':
          this.title = 'Nộp tiền | Rút tiền';
          this.subTitle = 'Thông tin Nộp tiền | Rút tiền';
          break;
        case 'transfer':
          this.title = 'Chuyển quỹ';
          this.subTitle = 'Thông tin chuyển quỹ';
          break;
        default:
          break;
      }
    },
    isShow(conditions) {
      return checkPermissions(conditions);
    },
    loadBreadcum: function() {
      this.loadTitle();
      const mode = this.$route.params.mode;
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: `${
            mode === 'cash'
              ? 'Danh sách thu chi tiền mặt'
              : 'Danh sách thu chi ngân hàng'
          }`,
          route: `transactions/${mode}`,
        },
        { title: this.title },
      ]);
    },
    fetchAccountants: async function() {
      const mode = this.$route.params.mode;
      let params = {
        type: mode === 'cash' ? '1' : '2',
      };

      if (mode === 'transfer') {
        params.type = ['1', '2', '3'];
      }

      if (mode === 'bank') {
        params.type = ['2', '3'];
      }

      let url = cmdUrl.AccountantUrl.byType;
      if (this.selectedStoreId !== null && mode === 'cash') {
        params.storeId = this.selectedStoreId;
        url = 'accountants/getByStore';
      }
      ApiService.query(url, {
        params,
      }).then((response) => {
        const data = response.data.data || [];
        _.map(data, (item) => {
          this.filterModel.accountants.push({
            value: item.code,
            text: item.name,
          });
        });
        this.optionAccountants = [...this.filterModel.accountants];
      });
    },
    fetchAllAccountants: async function() {
      ApiService.query(cmdUrl.AccountantUrl.getAll, {}).then((response) => {
        const data = response.data.data || [];
        _.map(data, (item) => {
          this.filterModel.accountantRefs.push({
            value: item.code,
            text: item.name,
          });
        });
        this.optionAccountantsRef = [...this.filterModel.accountantRefs];
      });
    },
    // FOR CONTACT ITEM
    searchContacts: function(searchText) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.mainModel.contactName = searchText;
        const { contactType } = this.mainModel;
        let params = {
          name: searchText,
          search: searchText,
          page: 1,
          pageSize: 10,
          currentSort: 'name',
          currentSortDir: 'asc',
        };
        let apiUrl = '';
        switch (contactType) {
          case '1':
            apiUrl = cmdUrl.CustomerUrl.root;
            params = {
              name: '',
              phone: searchText,
              page: 1,
              limit: 10,
            };
            break;
          case '2':
            apiUrl = cmdUrl.ProviderUrl.root;
            break;
          case '3':
            apiUrl = cmdUrl.InstallmentsUrl.root;
            break;
          case '4':
            apiUrl = cmdUrl.EmployeeUrl.root;
            params.fullName = searchText;
            break;
          default:
            break;
        }
        if (apiUrl === '') {
          this.filterModel.contacts = [];
          return;
        }
        ApiService.query(apiUrl, {
          params: {
            ...params,
            param: params,
          },
        }).then((response) => {
          const data = response.data.data || response.data;
          let listResult = [];
          _.forIn(data, function(value) {
            if (_.isArray(value)) {
              listResult = value;
            }
          });
          let listDisplay = [];
          _.map(listResult, (item) => {
            listDisplay.push({
              value: item.id,
              text:
                (item.name || item.fullName) +
                `${item.phoneNo ? ' - ' + item.phoneNo : ''}`,
              extra: item,
            });
          });
          this.filterModel.contacts = [];
          this.filterModel.contacts = [...listDisplay];
        });
      }, this.debounceMilliseconds);
    },
    onSelect(suggestion, type) {
      switch (type) {
        case 'Accountants': {
          this.mainModel.accountantId = suggestion.item.value;
          this.mainModel.accountantName = suggestion.item.text;
          break;
        }
        case 'AccountantRefs': {
          this.mainModel.accountantRefId = suggestion.item.value;
          this.mainModel.accountantRefName = suggestion.item.text;
          break;
        }
        case 'Contact': {
          this.mainModel.contactId = suggestion.item.value;
          this.mainModel.contactName = suggestion.item.text;
          break;
        }
        default:
          break;
      }
    },
    onInputChangeAccountants(text) {
      if (!text) {
        text = '';
        this.$v.mainModel.accountantName.$touch();
      }
      this.mainModel.accountantName = text;
      const filteredData = this.optionAccountants
        .filter((item) => {
          const nameNoneSign = xoa_dau(item.text).trim();
          const searchTermNoneSign = xoa_dau(text).trim();
          return (
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign
              .toLowerCase()
              .indexOf(searchTermNoneSign.toLowerCase()) > -1
          );
        })
        .slice(0, 10);

      this.filterModel.accountants = [...filteredData];
    },
    onInputChangeAccountantRefs(text) {
      if (!text) {
        text = '';
      }
      this.mainModel.accountantRefName = text;
      const filteredData = this.optionAccountantsRef
        .filter((item) => {
          const nameNoneSign = xoa_dau(item.text).trim();
          const searchTermNoneSign = xoa_dau(text).trim();

          return (
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign
              .toLowerCase()
              .indexOf(searchTermNoneSign.toLowerCase()) > -1
          );
        })
        .slice(0, 10);

      this.filterModel.accountantRefs = [...filteredData];
    },
    onChangeContactType: function() {
      this.filterModel.contacts = [];
    },
    validateItem(name) {
      if (this.firstCheck[name]) {
        this.firstCheck[name] = false;
        return true;
      }
      const { $dirty, $error } = this.$v.mainModel[name];
      return $dirty ? !$error : null;
    },

    // SUBMIT DATA
    onSubmit: async function() {
      if (this.checkReponse) {
        this.mainModel.createdAt = moment(
          this.mainModel.createdAt,
          'DD/MM/yyyy',
        ).toDate();

        this.$v.mainModel.$touch();
        if (this.$v.mainModel.$anyError) {
          return;
        }

        const action = this.$route.params.action;
        let params = Object.assign({}, this.mainModel);
        params.amount = _.toNumber(unMaskPrice(params.amount));

        // For transfer mode
        if (this.mode === 'transfer') {
          this.mainModel.type = '2';
          this.mainModel.contactType = CONTACT_TYPE.Other;
        }

        const apiAction = action === 'add' ? ApiService.post : ApiService.patch;
        const apiUrl =
          action === 'add'
            ? cmdUrl.TransactionUrl.root
            : cmdUrl.TransactionUrl.byId.replace('{id}', params.id);

        this.checkReponse = false;
        apiAction(apiUrl, params)
          .then((response) => {
            const { status, message } = response.data;
            if (status === 1) {
              this.makeToastSuccess(message);
              history.back();
            } else {
              this.makeToastFaile(message);
            }
            this.checkReponse = true;
          })
          .catch((err) => {
            if (!err.response) {
              // network error
              this.makeToastFailure('Lỗi!');
            } else {
              const message = err.response.data.message;
              this.makeToastFailure(message);
            }
            this.checkReponse = true;
          });
      }
    },

    // GET INFO
    fetchTransactionInfo: async function() {
      const { action, id } = this.$route.params;
      if (action !== 'edit') {
        return;
      }
      // Get data from api.
      const apiUrl = cmdUrl.TransactionUrl.byId.replace('{id}', id);
      ApiService.query(apiUrl, {}).then((response) => {
        const data = response.data.data || {};
        data.createdAt = new Date(data.createdAt);
        // swap data accountantId and accountantRefId if type :2

        if (data.type === '2' && data.accountantRefName) {
          // let accountTmp = data.accountantRefId;
          // data.accountantRefId = data.accountantId;
          // data.accountantId = accountTmp;
        } else if (data.type === '1' && data.accountantRefName) {
          // để nguyên
        } else {
          data.accountantRefId = 0;
        }

        this.mainModel = data;
        // nếu phiếu thu chi là tự động tạo sẽ không hiện nút lưu
        this.isShowSubmit = !this.mainModel.isAuto ? true : false;
      });
    },
    fetchTransactionCategory: function() {
      const apiUrl = cmdUrl.TransactionCategory.root;
      ApiService.query(apiUrl, {}).then((response) => {
        const data = response.data.data || null;
        if (data) {
          this.typeChilds = data.list_caterogy;
          let list = this.recursionChildType(data.list_caterogy, []);
          this.typeChildsOld = list;
          this.typeChilds = list;
        }
      });
    },
    genLevel: function(level) {
      let str = '';
      if (level) {
        for (let i = 0; i < level; i++) {
          str += ' - ';
        }
      }
      return str;
    },
    recursionChildType: function(items, listData = []) {
      let level0 = items.filter((x) => x.level === 0);
      if (level0.length > 0) {
        for (const item of level0) {
          listData.push({
            html: `${item.name}`,
            value: item.id,
            disabled: true,
            ...item,
          });
          let childs = this.typeChilds.filter((x) => x.parentId === item.id);

          if (childs.length > 0) {
            this.recursionChildType(childs, listData);
          }
        }
      } else {
        for (const item of items) {
          listData.push({
            html: `<p>${this.genLevel(item.level)} ${item.name}</p>`,
            value: item.id,
            ...item,
          });
          let childs = this.typeChilds.filter((x) => x.parentId === item.id);
          if (childs.length > 0) {
            this.recursionChildType(childs, listData);
          }
        }
      }
      return listData;
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) this.selectedStoreId = stores[0].id;
        }
        this.fetchAccountants();
      });
    },
    changeTypeParents(id) {
      if (id === null) {
        this.mainModel.contactType = null;
      } else {
        const currentCate = this.typeChildsOld.find((x) => x.id == id);
        const items = this.getParentTypes(id);

        if (items.length > 0) {
          let lastItem = items[items.length - 1].id;
          const mode = this.$route.params.mode;

          if (mode === 'cash') {
            this.mainModel.type = lastItem;
          } else if (mode === 'bank') {
            this.mainModel.type = lastItem === 1 ? 3 : 4;
          }

          this.mainModel.contactType = currentCate.contactId
            ? currentCate.contactId === 1
              ? CONTACT_TYPE.Other
              : 2 <= currentCate.contactId && currentCate.contactId <= 10
              ? CONTACT_TYPE.Employee
              : currentCate.contactId === 11
              ? CONTACT_TYPE.Provider
              : currentCate.contactId === 12
              ? CONTACT_TYPE.Customer
              : currentCate.contactId === 22
              ? CONTACT_TYPE.ShipFee
              : currentCate.contactId === 20
              ? CONTACT_TYPE.Other
              : null
            : null;

          if (!currentCate.defaultAccountId)
            return (this.mainModel.accountantRefId = 0);

          const item = this.filterModel.accountantRefs.find(
            (x) => x.value == currentCate.defaultAccountId,
          );
          this.mainModel.accountantRefId = item ? item.value : 0;
          this.mainModel.accountantRefName = item ? item.text : '';
        }
      }
    },
    changeTypeChilds(id) {
      const action = this.$route.params.action;
      if (action === 'add') {
        this.mainModel.contactType = null;
      }
      this.mainModel.accountantRefId = 0;
      if (id === null) {
        this.mainModel.typeChild = null;
        let list = this.typeChildsOld;
        this.typeChilds = list;
        return;
      }

      const mode = this.$route.params.mode;
      let parentId = null;

      if (mode === 'cash') {
        parentId = id;
      } else if (mode === 'bank') {
        parentId = id == 3 ? 1 : 2;
      }

      const currentCate = this.typeChildsOld.find(
        (child) => child.id == parentId,
      );
      const items = this.getChildTypes(parentId);

      items.unshift(currentCate);
      const itemsChanged = this.typeChildsOld.filter((type) =>
        items.map((item) => item.id).includes(type.id),
      );
      this.typeChilds = itemsChanged;
      this.mainModel.typeChild = null;
    },
    getParentTypes(typeId, items = []) {
      let item = this.typeChildsOld.find((x) => x.id === typeId);
      if (item) {
        items.push(item);
        if (item.parentId) this.getParentTypes(item.parentId, items);
      }
      return items;
    },
    getChildTypes(typeId, items = []) {
      let childs = this.typeChildsOld.filter((x) => x.parentId == typeId);
      if (childs.length > 0) {
        for (const child of childs) {
          items.push(child);
          this.getChildTypes(child.id, items);
        }
      }
      return items;
    },
  },
  updated() {
    this.mode = this.$route.params.mode;
  },
  watch: {
    'mainModel.contactType': function(newVal, oldVal) {
      let isReloadAccountant = false;
      if (oldVal !== newVal) {
        if (oldVal !== CONTACT_TYPE.Credit && newVal !== CONTACT_TYPE.Credit) {
          isReloadAccountant = false;
        } else {
          isReloadAccountant = true;
        }
      }
      if (isReloadAccountant) {
        this.filterModel.accountants = [];
        if (!this.editFromList) {
          this.editFromList = true;
        } else {
          this.mainModel.accountantId = null;
          this.mainModel.accountantName = '';
        }
        this.fetchAccountants();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>

<style lang="scss">
.transactions-cash {
  input.form-control {
    border: 1px solid #ced4da;
  }
  textarea.form-control {
    border: 1px solid #ced4da;
  }
  .upload-file {
    .dropbox {
      margin: auto;
      width: 70%;
      background: #f8f8f8;
      border-radius: 20px;
      color: dimgray;
      padding: 10px 10px;
      min-height: 200px;
      position: relative;
      cursor: pointer;
    }

    .input-file {
      opacity: 0; /* invisible but it's there! */
      left: 0px;
      width: 100%;
      height: 200px;
      position: absolute;
      cursor: pointer;
    }

    .dropbox:hover {
      background: #e8f5e9;
    }

    .dropbox p {
      font-size: 1.2em;
      text-align: center;
      padding: 50px 0;
    }
    .img-container {
      position: relative;
      display: inline-block;
      width: 80px;
      margin: 0px 10px;
    }
    .preview-img {
      max-width: 80px;
      padding: 10px;
      object-fit: contain;
      border: 1px solid #eeecff;
      border-radius: 5px;
    }
    .preview-img-container {
      border: 2px dashed #eeecff;
      padding: 2rem 1rem;
    }

    .preview-box {
      display: inline-block;
    }
    .close {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 15px;
    }
    .remove {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 15px;
    }
    .cancel {
      color: #545454;
      text-decoration: none;
    }
  }
}
</style>
